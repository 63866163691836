import { Controller } from 'stimulus';

export default class extends Controller {
	
  static targets = [ "results" ]
	connect() {
   
  }

  display(){
    this.resultsTargets.forEach(element => {
      element.classList.add('d-none')
    });

    const mealChoosen = event.currentTarget.dataset.meal
    const meals = document.getElementById(mealChoosen)
    meals.classList.remove('d-none')
  }
}