import { Controller } from 'stimulus';

export default class extends Controller {
	
  static targets = [ "price" ]
	connect() {

   
  }


  display(){

    if (event.target.value === "true"){
      this.priceTarget.classList.add('d-none')
    }
    if (event.target.value === "false"){
      this.priceTarget.classList.remove('d-none')
    }
  }
}