import Glide, { Autoplay, Controls, Breakpoints, Swipe } from '@glidejs/glide/dist/glide.modular.esm';

const glide = () => {

  if (document.querySelector("#cooker-slider")) {
    new Glide('#cooker-slider', {
      autoplay: 2000,
      gap: 16,
      peek: {
        before: 0,
        after: 0
      },
      perView: 5,
      breakpoints: {
        576: {
          perView: 3
        }
      },
      type: "carousel"
    }).mount({Autoplay, Controls, Breakpoints, Swipe});
  } 
  if (document.querySelector("#product-slider")) {
    new Glide('#product-slider', {
      autoplay: 2000,
      gap: 16,
      peek: {
        before: 0,
        after: 0
      },
      perView: 3,
      breakpoints: {
        576: {
          perView: 2
        }
      },
      type: "carousel"
    }).mount({Autoplay, Controls, Breakpoints, Swipe})
  }
}


export { glide };
