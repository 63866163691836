import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["items", "list", "input", "price", "total"];

  static values = { 
    item: String, 
    price: String, 
    initialShoppingPrice: Number
  };

  provide(event) {
    event.preventDefault();
    const itemValue = event.currentTarget.getAttribute("data-provide-ingredients-item-value");
    const priceValue = event.currentTarget.getAttribute("data-provide-ingredients-price-value");
    
    // Toggle the selection state
    event.currentTarget.classList.toggle("fa-square");
    event.currentTarget.classList.toggle("fa-check-square");
    
    if (event.target.classList.contains("fa-check-square")) {
      this.addListItem(itemValue, priceValue);
    } else {
      this.removeListItem(itemValue);
    }

    this.updateSelectedItemsList();

    this.updateTotal();
  }

  addListItem(itemValue, priceValue) {
    document.querySelector("#meals-provided-by-customer").classList.remove("d-none");
    const listItem = document.createElement("li");
    listItem.innerHTML = `${itemValue} <div class="d-none">-${priceValue}</div`;
    this.listTarget.appendChild(listItem);
  }

  removeListItem(itemValue) {
    const listItems = Array.from(this.listTarget.children);
    const listItemToRemove = listItems.find(item => item.innerText.includes(itemValue));
  
    if (listItemToRemove) {
      listItemToRemove.remove();
    }
  }

  updateSelectedItemsList() {
    if(this.listTarget.children.length === 0) {
      document.querySelector("#meals-provided-by-customer").classList.add("d-none");
    }
    const selectedItems = this.getSelectedItems();
    this.inputTarget.value = JSON.stringify(selectedItems.map(item => item.value));
  }

  getSelectedItems() {
    const selectedItems = [];

    this.itemsTargets.forEach(item => {
      if (item.classList.contains("fa-check-square")) {
        selectedItems.push({
          value: item.getAttribute("data-provide-ingredients-item-value"),
          price: item.getAttribute("data-provide-ingredients-price-value")
        });
      }
    });

    return selectedItems;
  }

  updateTotal() {
    let total = 0.0;
    this.getSelectedItems().forEach(item => {
      console.log(item.price)
      console.log(parseFloat(item.price.replace(',', '.')));
      total += parseFloat(item.price.replace(',', '.'));
      console.log(total);
    });

    this.totalTarget.innerText = `${total.toFixed(2)}`;
    this.priceTarget.value = total.toFixed(2);

    let initialPrice = parseFloat(document.querySelector("#initial-shopping-price").dataset.initialShoppingPrice.replace(',', '.'));
    console.log(initialPrice);
    document.querySelector("#final-shopping-price").innerText = (initialPrice - total).toFixed(2);
  }
}