const cookiesManagement = () => {
  
  if (document.readyState === "complete" || document.readyState === "interactive") {
    if (document.cookie.indexOf("accepted_cookies=") < 0) {
      const cookie = document.querySelector('.cookie-overlay'); 
      cookie.classList.remove('d-none');

      const acceptCookie = document.querySelector('.accept-cookies'); 
 
      acceptCookie.addEventListener('click', () =>{
        document.cookie = "accepted_cookies=yes;"
        cookie.classList.add('d-none')
     
      } )

    }

}

}


export {cookiesManagement}
