const closeHomepagePopup = () => {
	if (document.readyState === 'complete' || document.readyState === 'interactive') {
		// If the popup has already been closed :

		if (getCookie('accepted_homepagePopup') === 'yes') {
			document.querySelector('.overlay').classList.remove('open-popup');
			document.querySelector('.overlay').classList.add('close-popup');
			document.querySelector('#popup-section').style.display = 'none';
		} else {
			const close_buttons = document.querySelectorAll('.closer');
			close_buttons.forEach((close_button) => {
				close_button.addEventListener('click', (event) => {
					setCookie('accepted_homepagePopup', 'yes');

					document.querySelector('.overlay').classList.remove('open-popup');
					document.querySelector('.overlay').classList.add('close-popup');
					document.querySelector('#popup-section').style.display = 'none';
				});
			});
		}
	}
};

const getCookie = (cName) => {
	const name = cName + '=';
	const cDecoded = decodeURIComponent(document.cookie); //to be careful
	const cArr = cDecoded.split('; ');
	let res;
	cArr.forEach((val) => {
		if (val.indexOf(name) === 0) res = val.substring(name.length);
	});
	return res;
};

const setCookie = (cName, cValue) => {
	document.cookie = cName + '=' + cValue + '; ';
};

export { closeHomepagePopup };
