import { Controller } from 'stimulus';

export default class extends Controller {
	static values = {
		actualPrice: Number,
		shoppingByCooker: Boolean
	};

	static targets = [ 'price' ];
	connect() {}

	chooseMeal(event) {
		const btn = event.target;
		event.preventDefault();
		const countingMeals = document.querySelectorAll('.counter');
		const nbOfMealsChoosen = document.querySelector('#nb-of-meals-choosen');
		const nbOfMealsMax = nbOfMealsChoosen.dataset.nbMax;
		const id = event.currentTarget.dataset.mealId;
		const counter = document.querySelector(`#counter-meal-${id}`);
		let sum = parseInt(counter.innerText, 10);
		const nb = btn.dataset.offset;
		const selectMeal = document.querySelector(`#meal-${id}`);

		// On fait la somme de plats
		if (parseInt(nbOfMealsChoosen.innerText, 10) <= nbOfMealsMax - 1) {
			sum += parseInt(nb, 10);
		} else if (parseInt(nb, 10) < 0) {
			sum += parseInt(nb, 10);
		}

		// On met à jour la vue pour tous les plats
		if (sum >= 0) {
			counter.innerText = sum;
			selectMeal.dataset.quantity = sum;
		}

		// On affiche les meals sélectionnés
		const choosenMeals = document.querySelector('.choosen-meals-list');
		const choosenMealToBeStock = document.querySelectorAll('.meal-choosed');
		let stockMealsChoosen = {};

		if (choosenMealToBeStock.length !== 0) {
			choosenMealToBeStock.forEach((meal) => {
				stockMealsChoosen[meal.dataset.mealId] = {
					name: meal.dataset.mealName,
					id: meal.dataset.mealId,
					quantity: meal.dataset.quantity,
					price: meal.dataset.mealPrice
				};
			});
		}

		const keys1 = Object.keys(stockMealsChoosen);

		if (keys1.includes(selectMeal.dataset.mealId)) {
			stockMealsChoosen[selectMeal.dataset.mealId] = {
				name: selectMeal.dataset.mealName,
				id: selectMeal.dataset.mealId,
				quantity: selectMeal.dataset.quantity,
				price: selectMeal.dataset.mealPrice
			};
		} else {
			stockMealsChoosen[`${selectMeal.dataset.mealId}`] = {
				name: selectMeal.dataset.mealName,
				id: selectMeal.dataset.mealId,
				quantity: selectMeal.dataset.quantity,
				price: selectMeal.dataset.mealPrice
			};
		}

		const keys2 = Object.keys(stockMealsChoosen);

		let totalPrice = 0.0;

		let listOfMealsChoosen = '';
		let sumMeal = 0;
		let price = '';
		keys2.forEach((key) => {
			if (stockMealsChoosen[key]['quantity'] > 0) {
				if (this.shoppingByCookerValue) {
					price = ` x ${stockMealsChoosen[key]['price']} €`;
				}
				listOfMealsChoosen += `<div class="meal-choosed" data-meal-name="${stockMealsChoosen[key][
					'name'
				]}" data-meal-id="${stockMealsChoosen[key]['id']}" data-quantity="${stockMealsChoosen[key][
					'quantity'
				]}" data-meal-price="${stockMealsChoosen[key]['price']} ">
              <div class="choosen"><i class="fas fa-check"></i>${stockMealsChoosen[key]['name']}</div>
              <div class="meal-quantity small">${stockMealsChoosen[key]['quantity']}${this.shoppingByCookerValue ? price : ''}</div>
            </div>`;
			}
      let price = parseFloat(stockMealsChoosen[key]['price'].replace(',', '.'));
      console.log(price);
      console.log(typeof(price));
      totalPrice += parseFloat(stockMealsChoosen[key]['quantity']) * price;
      sumMeal += parseFloat(stockMealsChoosen[key]['quantity']);
		});

		const progress_bar = document.querySelector('.progress-bar');
		// const ariaValueNow = progress_bar.ariaValueNow;
		progress_bar.ariaValueNow = sumMeal;
		progress_bar.style.width = sumMeal * 100 / nbOfMealsMax + '%';

		nbOfMealsChoosen.innerText = sumMeal;

		this.priceTarget.innerHTML = '';

		choosenMeals.innerHTML = listOfMealsChoosen;

		if (this.shoppingByCookerValue) {
			choosenMeals.insertAdjacentHTML(
				'beforeend',
				` <div class="line-lg"></div>
				<p class="mb-0 d-flex justify-content-between" ><span>Total des courses :</span><span> ${(totalPrice +
					this.actualPriceValue).toFixed(2)}€</span></p>
				`
			);
		}
	}
}
