import { Controller } from 'stimulus';
import swal from 'sweetalert';

export default class extends Controller {
	connect() {}

	message(event) {
		event.preventDefault();
		swal('Votre panier est vide.', {
			button: false
		});
	}
}
