import swal from 'sweetalert';

const loadingSweetalert = () => {

  const button = document.querySelector('#booking-creation');

  const div = document.createElement("div")

  const startDate = document.querySelector('#booking_start_date');

  const shopping = document.getElementsByName("booking[shopping_by_cooker]")

  div.className = "spinner-border text-primary"
  div.role = "status"
  div.style = "width: 5rem; height: 5rem;"
  div.innerHTML = "<span class='sr-only'>Loading...</span>"

  if (button) {
    button.addEventListener('click', () => {
      if (startDate.value !== "") {
        swal({
          title: 'Nous recherchons des cuisiniers.',
          text: 'Cela ne prendra que quelques instants.',
          content: div,
          closeOnEsc: false,
          closeOnClickOutside: false,
          showSpinner: true,
          buttons: false,
        })
      }
    })
  }

 

}

export { loadingSweetalert };