import { Controller } from 'stimulus';

export default class extends Controller {
	static targets = [ 'create' ];
	connect() {}

	selectProductQuantity(event) {
		// const selected_button = event.target;
		event.preventDefault();
		const initial_counter = parseInt(document.querySelector('.counter').innerText, 10);
		const selected_quantity = parseInt(event.target.dataset.offset, 10);

		let updated_counter = initial_counter + selected_quantity;

		if (updated_counter !== 0) {
			document.querySelector('.counter').innerText = updated_counter;
			// I update the quantity for the creation of a shop-product-cart
			const regex = new RegExp('=.*');
			this.createTarget.href = this.createTarget.href.replace(regex, `=${updated_counter}`);
		}
	}
}
