import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["items", "editPopup", "addPopup"];
  static values = {index: String};

  addIngredient(event) {
    event.preventDefault();
    console.log(this.element)
    
    const nameInput = this.element.querySelector(".meal_new_ingredient_name input");
    const priceInput = this.element.querySelector(".meal_new_ingredient_price input");
    console.log(nameInput);
    console.log(priceInput);

    const ingredientName = nameInput.value.trim();
    const ingredientPrice = this.formatPriceInput(priceInput.value.trim()); 

    // Get existing ingredients 
    const ingredientsData = JSON.parse(this.itemsTarget.value);
    console.log(`Existing list: ${JSON.stringify(ingredientsData)}`);
    
    // Push the new ingredient
    ingredientsData.push([ingredientName, ingredientPrice]);
    console.log(`New list: ${JSON.stringify(ingredientsData)}`);

    // Append the new ingredient to the existing list in the input field
    this.itemsTarget.value = JSON.stringify(ingredientsData);
    console.log(this.itemsTarget.value);
    
    // Insert the new ingredient into the list
    const list = this.element.querySelector('.ingredients-list');
    const newIngredient = document.createElement('li');
    newIngredient.innerHTML = `<strong>${ingredientName}</strong> - ${ingredientPrice} €<i class="fas fa-trash-can ml-3" data-action="click->ingredients#deleteIngredient" data-index="${ingredientsData.length - 1}"></i>`;
    list.appendChild(newIngredient);

    // Clear the input fields
    nameInput.value = '';
    priceInput.value = '';

    // Close the popup
    console.log(this.addPopupTarget);
    $(this.addPopupTarget).modal('hide');
  }

  deleteIngredient(event) {
    event.preventDefault();
    const indexToRemove = parseInt(event.target.dataset.index)
   
    // Get existing ingredients 
    const ingredientsData = JSON.parse(this.itemsTarget.value);
    console.log(`Existing list: ${JSON.stringify(ingredientsData)}`);
    
    // Remove the ingredient from the input field
    ingredientsData.splice(indexToRemove, 1);
    console.log(`New list: ${JSON.stringify(ingredientsData)}`);

    // Update the list in the input field
    this.itemsTarget.value = JSON.stringify(ingredientsData);
    console.log(this.itemsTarget.value);

    // Remove the ingredient from the DOM
    const ingredientFields = event.currentTarget.closest('li');
    ingredientFields.remove();

    // Update dataset indices
    const trashIcons = document.querySelectorAll('#ingredients-list i[data-action="click->ingredients#deleteIngredient"]');
    trashIcons.forEach((icon, idx) => {
      icon.dataset.index = idx;
    });

  }

  openEditPopup(event) {
    event.preventDefault();
    const indexToEdit = parseInt(event.target.dataset.index);

    this.indexValue = indexToEdit;

    // Get existing ingredients
    const ingredientsData = JSON.parse(this.itemsTarget.value);
    const [ingredientName, ingredientPrice] = ingredientsData[indexToEdit];

    // Populate the edit form with existing values
    const editPopup = this.editPopupTarget;
    const nameInput = editPopup.querySelector('[name$="[name]"]');
    const priceInput = editPopup.querySelector('[name$="[price]"]');
    nameInput.value = ingredientName;
    priceInput.value = this.formatPriceInputBack(ingredientPrice); // Use a reverse formatting function
    console.log(typeof priceInput.value)

    // Show the edit popup using Bootstrap modal method
    $(editPopup).modal('show');
  }

  closeEditPopup(event) {
    event.preventDefault();
    $(this.editPopupTarget).modal('hide');
  }

  saveEditedIngredient(event) {
    event.preventDefault();
    const indexToEdit = parseInt(this.indexValue);  
    const nameInput = this.editPopupTarget.querySelector('[name$="[name]"]');
    const priceInput = this.editPopupTarget.querySelector('[name$="[price]"]');
  
    // Update the ingredient in the DOM
    const editedIngredient = this.element.querySelector(`ul li.item-${indexToEdit} span`);
    console.log(editedIngredient);

    editedIngredient.innerHTML = 
      `<strong>${nameInput.value.trim()}</strong> - ${this.formatPriceInput(priceInput.value.trim())} €`;

    // Update the list that will be stored the database

    // Get existing ingredients 
    const ingredientsData = JSON.parse(this.itemsTarget.value);
    console.log(`Existing list: ${JSON.stringify(ingredientsData)}`);
    
    // Update the ingredient
    ingredientsData[indexToEdit] = [nameInput.value.trim(), this.formatPriceInput(priceInput.value.trim())];
    console.log(`Updated list: ${JSON.stringify(ingredientsData)}`);

    this.itemsTarget.value = JSON.stringify(ingredientsData);
    console.log(this.itemsTarget.value);

    // Close the edit popup 
    $(this.editPopupTarget).modal('hide');
  }
  

  formatPriceInput(input) {
    const separator = input.includes(',') ? ',' : '.';
    const parts = input.split(separator);

    if (parts.length === 2 && parts[1].length > 2) {
      parts[1] = parts[1].substring(0, 2); // Keep only the first two digits after the comma
    }
    return parts.join(',');
  }

  formatPriceInputBack(input) {
    // Remove commas and convert the string to a float
    const plainNumber = parseFloat(input.replace(',', '.'));
    console.log(plainNumber);
    // Check if the conversion was successful
    if (!isNaN(plainNumber)) {
      return plainNumber;
    } else {
      // Handle the case where the input is not a valid number
      console.error(`Invalid input: ${input}`);
      return null; // or any default value
    }
  }

}