import flatpickr from "flatpickr";
import { French } from "flatpickr/dist/l10n/fr.js"

const addDays =  (days)  =>{
  const result = new Date();
  result.setDate(result.getDate() + days);
  return result;
}


const initFlatpickr = () => {

  const minClient = addDays(2);
  const minDayClient = minClient.getUTCDate();
  const minYearClient = minClient.getUTCFullYear();
  const minMonthClient = minClient.getUTCMonth()+1



  flatpickr(".datepicker", {
    minDate: `${minYearClient}-${minMonthClient}-${minDayClient}`,
    "locale": French
  });

  flatpickr("#cooking-session-search", {
    "locale": French,
    placeholder: "Choisir une date"
/*     dateFormat: "d m Y" */
  });


  // Les cuisiniers ne peuvent pas créer de dispos avant 24h
  const min = addDays(1);
  const minDay = min.getUTCDate();
  const minYear = min.getUTCFullYear();
  const minMonth = min.getUTCMonth()+1

  flatpickr(".datepickercooker", {
    altInput: true,
    allowInput: true,
    dateFormat: "Y-m-d H:i",
    minDate: `${minYear}-${minMonth}-${minDay}`,
    weekNumbers: true,
  });

}

export { initFlatpickr };



