import $ from 'jquery';
import 'select2';


const initSelect2 = () => {
  const placeholderUpdated = document.getElementById('placeholderUpdated')
  if (placeholderUpdated) {
    $('.select2-cooker').select2({
      allowClear: true,
      placeholder: "Choisir un cuisinier"
    });
  
    $('.select2-customer').select2({
      allowClear: true,
      placeholder: "Choisir un client"
    });
  }
};





export { initSelect2 };
