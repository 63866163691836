import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "form" ]

  connect() {
    console.log("Hello from toggle_controller.js")
  }

  fire() {
    this.formTarget.classList.toggle("d-none");
  }
}