const displayCooksCalendarButtons = () => {
  const time_buttons = document.querySelectorAll(".time-btn")

  if (time_buttons) {
    // On page load : hide all buttons after the 4th button of a cooker
    time_buttons.forEach((time_button) => {
      if (time_button.dataset.btnId > 3) {
        time_button.style.display = "none"
      }
    })
    // When the user clicks on a "show more" button
    const show_more_timeslots_buttons = document.querySelectorAll(".show_more_timeslots");
    show_more_timeslots_buttons.forEach((show_more_timeslots_button) => {
      show_more_timeslots_button.addEventListener("click", (event) => {
        // Store the cooker_id in a variable
        const cooker_id = event.currentTarget.dataset.cooker
        // Hide the "show more" button
        event.currentTarget.style.display = "none"
        // Display all the buttons of this cooker
        time_buttons.forEach((time_button) => {
          if (time_button.dataset.cooker === cooker_id) {
            time_button.style.display = "block"
          }
        })
      })
    })
  }
}

export {displayCooksCalendarButtons}
