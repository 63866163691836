const displayCooksAvailability = () => {
  // const buttons = document.querySelectorAll(".session-breadcrumb")



  // if (buttons) {
  //   buttons.forEach((button)=> {
  //     button.addEventListener('click', () =>{

  //       const selected = document.querySelector(".selected")
  //       if (selected) {
  //         selected.classList.remove('selected')
  //       }
  //       event.target.classList.add('selected')

  //       const sessionSelected = event.target.id.charAt(19)
  //       const sessionChoosen = document.getElementById(`${sessionSelected}`)
  //       const allSessions = document.querySelectorAll(".sessions")

  //       allSessions.forEach((session) =>{
  //         session.classList.add('d-none')
  //       })
  //       sessionChoosen.classList.remove('d-none')
  //     })

  //   })
  // }



  const timeSlots = document.querySelectorAll(".time-btn-available");
  const calendar = document.getElementById('booking-calendar');

  if (calendar) {
    timeSlots.forEach((timeSlot) => {
      timeSlot.addEventListener('click', () => {
  
        
        // const current = parseInt(event.target.dataset.session.charAt(8), 10)
        // const next = current + 1
  
        const displayTime = document.getElementById(`session`)
  
        const highlighedtTime = document.querySelector('.light-green')
        if (highlighedtTime){
          highlighedtTime.classList.remove('light-green')
        }
  
        // displayTime.classList.add('light-green')
        // const allSessions2 = document.querySelectorAll(".sessions")
  
        // if (next < 5) {
  
        //   const selected2 = document.querySelector(".selected")
        //   if (selected2) {
        //     selected2.classList.remove('selected')
        //   }
  
        //   const nextSession = document.getElementById(`session-breadcrumb-${next}`)
        //   if (nextSession) {
        //     nextSession.classList.add('selected')
        //   }
  
  
        //   const sessionChoosen2 = document.getElementById(`${next}`)
  
        //   if (2 <= allSessions2.length) {
  
        //     allSessions2.forEach((session) =>{
        //       session.classList.add('d-none')
        //     })
  
        //     sessionChoosen2.classList.remove('d-none')
        //   }
  
        // }
  
      })
    })
  }


}



export {displayCooksAvailability}
