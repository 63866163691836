
const createCookingSessionMeal = () => {

  const buttonCreate = document.getElementById('create-cooking-session-meal')
 
 


  if (buttonCreate) {

    const session = buttonCreate.dataset.session
    const cookingSession = buttonCreate.dataset.cookingSession
    const booking = buttonCreate.dataset.booking
    const nbOfMealToBeChoosen = parseInt(buttonCreate.dataset.nbMealToBeChoosen, 10)

    buttonCreate.addEventListener('click',(event) =>{
      event.preventDefault();
      
    // Je récupère le nombre de plats choisis
      const nbOfMealChoosen = parseInt(document.getElementById('nb-of-meals-choosen').innerHTML, 10)

    // Ne pas autoriser la création de meal si il n'a pas choisit le bon nombre de plats
      if (nbOfMealToBeChoosen === nbOfMealChoosen){

        // Récupération des plats choisis
        const mealsChoosen = document.querySelectorAll('.meal-choosed')
        const url = `/bookings/${booking}/choisir-mes-plats/${session}`

        let nb
        const form = document.getElementById(`new_cooking_session_meal`)

        for (let i = 0; i < mealsChoosen.length ; i++) {

          // Récupération des informations dont j'ai besoin pour crééer une entrée dans cooking-session-meal et je remplis le formulaire

            let input = document.createElement('input'); 
            input.type = 'hidden'; 
            input.name = `cooking_session_meal_${i + 1}`
            input.value = `{\"quantity\":\"${mealsChoosen[i].dataset.quantity}\", \"meal_id\": \"${mealsChoosen[i].dataset.mealId}\"}`; 
            form.appendChild(input);

            nb = i
 
         
        }
        let input1 = document.createElement('input'); 
        input1.type = 'hidden'; 
        input1.name = `nb_of_different_meal`
        input1.value = `${nb + 1}`; 
        form.appendChild(input1);

        let input2 = document.createElement('input'); 
        input2.type = 'hidden'; 
        input2.name = `session`
        input2.value = `${session}`; 
        form.appendChild(input2);

        let input3 = document.createElement('input'); 
        input3.type = 'hidden'; 
        input3.name = `cooking_session`
        input3.value = `${cookingSession}`; 
        form.appendChild(input3);
        // Je soumets le formulaire   
        form.submit()
        
      } else {
        alert("Merci de choisir le bon nombre de plats.")
      }
      
    })
  
    
  }
}


export {createCookingSessionMeal}
