import {fetchWithToken} from "../utils/fetchWithToken"
import { csrfToken } from "@rails/ujs";


// To book a cooker, I filled a hidden form with infos needed in the display_cookers_planning
const bookCookers = () => {

  const buttonCreate = document.getElementById('create-unavailabily')
  if (buttonCreate){

    buttonCreate.addEventListener('click', () => {
     const session = document.querySelector("#chosen-session")
     if (session) {
      console.log("Hello")
        const booking = buttonCreate.dataset.booking
        const form = document.getElementById(`edit_booking_${booking}`)
        let n = 1
  
        // Informations nécessaires pour la création d'un cook unavailability, je remplis le formulaire
        let input = document.createElement('input'); 
        input.type = 'hidden'; 
        input.name = `session`; 
        input.value = `{\"cook_availability\":\"${session.dataset.availability}\", \"cooking_session\": \"${session.dataset.session}\"}`; 
        form.appendChild(input);
        n += 1

        let bookingId = document.createElement('input'); 
        bookingId.type = 'hidden'; 
        bookingId.name = `booking`; 
        bookingId.value = `${booking}`; 
        form.appendChild(bookingId);
  
      form.submit()

     }

    }
    )

  }

}


export {bookCookers}
