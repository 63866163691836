import { Controller } from 'stimulus';

export default class extends Controller {

    connect() {
        console.log("Hello from animate cooker selection controller")

        const btnAvailbilities = document.querySelectorAll(".time-btn-available")
        
        btnAvailbilities.forEach((btn) => {

            btn.addEventListener('click', () =>{
                
                const choice = event.currentTarget

                // Update the color of the slot
                const timeChoosen = document.querySelector(".time-choosen")
                if (timeChoosen){
                timeChoosen.classList.remove("time-choosen")
                }
                choice.classList.add("time-choosen");

                //  Display the selected slot 
       
                const date = choice.dataset.date.split('-')
        
                document.getElementById("chosen-session").innerText =`le ${date[2]}/${date[1]}/${date[0]} à ${choice.dataset.timeSlot}h avec ${choice.dataset.cookerName}`  

                // Update the input field with the selected slot
                document.querySelector("#cook_availability_id").value = choice.dataset.idAvailability
            })
        })
    }

}