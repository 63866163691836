import { event } from "jquery";
import { Controller } from "stimulus";

let endOfUrl = []

export default class extends Controller {
  static targets = ['form', 'input', 'list', 'vegan', 'vegetarien', 'pesco', 'without_lactose', 'without_gluten', 'cuisine_du_monde', 'viande', 'poisson', 'petit_dejeuner_et_gouter', 'choosen', 'kids_friendly', 'post_partum', 'diet', 'button'];

  connect() {
  }

  choose(event) {
    let url = `${this.formTarget.action}?query=${event.currentTarget.dataset.category}`
    console.log(url)
  
    this.fetchingMeal(url)

    this.buttonTargets.forEach((target)=> {
      target.classList.remove('active')
    })

    event.currentTarget.classList.add('active');
  }

  update() {
    event.preventDefault();
    let url = `${this.formTarget.action}?query=${this.inputTarget.value}`
    url+= `&commit=Rechercher`
    this.fetchingMeal(url)
  }

  getValue(){
    const element = event.target.dataset.searchMealsTarget
    if (endOfUrl.includes(element)){
      const index = endOfUrl.indexOf(element);
      if (index > -1) {
        endOfUrl.splice(index, 1);
      }
    }
    else {
      endOfUrl.push(event.target.dataset.searchMealsTarget)
    }
  }



  fetchingMeal(url){

    fetch(url, { headers: { 'Accept': 'text/plain' } })
    .then(response => response.text())
    .then((data) => {
      this.listTarget.outerHTML = data;
    })
    .then(()=>{
      // Update the view with the meals that have already been choosen
      const mealsAlreadyChoosed = document.querySelectorAll('.meal-choosed')
      const counters = document.querySelectorAll('.counter')
      const proposeMeals = document.querySelectorAll('.proposed-meals')
      mealsAlreadyChoosed.forEach((meal) => {
        counters.forEach((counter) => {
          if (meal.dataset.mealId === counter.id.substr(13)) {
            counter.dataset.count = meal.dataset.quantity
            counter.innerHTML = meal.dataset.quantity
          }
        })

        proposeMeals.forEach((plat) => {
          if (plat.dataset.mealId === meal.dataset.mealId  ) {
            plat.dataset.quantity = meal.dataset.quantity
          }
        })
      })  
    })

  }

}