import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="bio"
export default class extends Controller {
  static targets = ["bioOption", "radioInput"]

  connect() {
    this.toggleBioOption();
  }

  toggleBioOption() {
    const selectedRadio = this.radioInputTargets.find(radio => radio.checked);

    if (selectedRadio && selectedRadio.value === '1') {
      this.bioOptionTarget.classList.remove("d-none");
    } else {
      this.bioOptionTarget.classList.add("d-none");
    }
  }

  changeOption(event) {
    this.toggleBioOption();
  }
}
