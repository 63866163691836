// app/javascript/components/photo_preview.js
const previewImageOnFileSelect = () => {
	// we select the photo input
	const input = document.getElementById('photo-input-0');
	const input_1 = document.getElementById('photo-input-1');
	const input_2 = document.getElementById('photo-input-2');
	if (input) {
		// we add a listener to know when a new picture is uploaded
		input.addEventListener('change', () => {
			// we call the displayPreview function (who retrieve the image url and display it)
			displayPreview(input);
		});
	}
	if (input_1) {
		// we add a listener to know when a new picture is uploaded
		input_1.addEventListener('change', () => {
			// we call the displayPreview function (who retrieve the image url and display it)
			displayPreview(input_1);
		});
	}
	if (input_2) {
		// we add a listener to know when a new picture is uploaded
		input_2.addEventListener('change', () => {
			// we call the displayPreview function (who retrieve the image url and display it)
			displayPreview(input_2);
		});
	}
};

const displayPreview = (input) => {
	if (input.files && input.files[0]) {
		const photo_number = input.id.slice(-1);

		const reader = new FileReader();
		reader.onload = (event) => {
			document.getElementById(`photo-preview-${photo_number}`).src = event.currentTarget.result;
		};
		reader.readAsDataURL(input.files[0]);
		document.getElementById(`photo-preview-${photo_number}`).classList.remove('d-none');
    input.parentNode.firstChild.innerText = "📷 Modifier"
    // document.getElementById(`photo-preview-${photo_number}`).classList.add('d-none');
	}
};

export { previewImageOnFileSelect };
