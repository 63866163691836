import swal from 'sweetalert';
import { initSweetalert } from '../plugins/init_sweetalert';

const cancelSweetalert = () => {

  const buttons = document.querySelectorAll('.sweet-alert-cancel-session');

  if (buttons) {

    buttons.forEach((button) => {
      button.addEventListener('click', () => {

        swal({
          title: "Etes-vous sûr(e) de vouloir annuler cette séance?",
          text: "Cette action est irréversible. Votre calendrier sera mis à jour.",
          icon: "warning",
          buttons: ["Fermer", "Confirmer"],
          dangerMode: true,
          
        })
   
        .then((willDelete) => {
          if (willDelete) {
            const link = document.querySelector(`#delete-link-cancel-session-${button.dataset.id}`);
            link.click();
            swal("Votre séance a bien été annulée", {
            icon: "success",
            });
          } 
        });   
      })
    })
      


  } 

 

}

export { cancelSweetalert };


