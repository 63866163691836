const formatTimeslots = () => {
  if (document.querySelector("#booking-timetable")){
    document.querySelectorAll(".timetable-title").forEach((timetable) => {
      timetable.addEventListener('click', (event) => {
        const details = document.querySelector(`#timetable-details-${timetable.id}`)
       
        if (details.style.display === "block") {
          details.style.display = "none";
          
        } else {
          details.style.display = "block";
         
        }
      })
    })
    document.querySelectorAll(".see-more-btn").forEach((button) => {
      button.addEventListener("click", (event) => {
        const day = button.id
        const bottom = document.querySelector(`#timeslots-bottom-${day}`)
        bottom.style.display = "grid";
        button.style.display = "none"
      })
    })
  }
}

export {formatTimeslots}
