// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import * as ActiveStorage from '@rails/activestorage';
import 'channels';

Rails.start();
Turbolinks.start();
ActiveStorage.start();

// ----------------------------------------------------
// Note(lewagon): ABOVE IS RAILS DEFAULT CONFIGURATION
// WRITE YOUR OWN JS STARTING FROM HERE 👇
// ----------------------------------------------------

// External imports
import 'bootstrap';
import 'cloudinary_js';

// Internal imports, e.g:
//import { mailChimp } from '../components/mailchimp';
import { cookiesManagement } from '../components/cookies';
import { previewImageOnFileSelect } from '../components/photo_preview';
import { formatTimeslots } from './format_timeslots';
import Glide, { Autoplay, Controls, Breakpoints, Swipe } from '@glidejs/glide/dist/glide.modular.esm';
import { initFlatpickr } from '../plugins/flatpickr';
import { chooseCook } from '../components/choose_cook';
import { bookCookers } from '../components/book_cookers';
import { createCookingSessionMeal } from '../components/create_cooking_session_meal';
import { displayCooksAvailability } from '../components/display_cooks_availabilty';
import { displayCooksCalendarButtons } from '../components/display_cooks_calendar_buttons';
import { glide } from '../components/glide';
import { dynamicRating } from '../components/animate_review_form';
import { initSweetalert } from '../plugins/init_sweetalert';
import { loadingSweetalert } from '../plugins/load_sweetalert';
import { cancelSweetalert } from '../plugins/cancel_session_by_cooker_sweetalert';
// import {autocompleteSearch} from '../components/autocomplete';
import { initSelect2 } from '../components/init_select2';
import { cloudinaryFileUpload } from '../components/cloudinary_file_upload';
import { closeHomepagePopup } from '../components/close_homepage_popup';

document.addEventListener('turbolinks:load', () => {
	cookiesManagement();
	previewImageOnFileSelect();
	formatTimeslots();
	initFlatpickr();
	chooseCook();
	bookCookers();
	createCookingSessionMeal();
	displayCooksAvailability();
	displayCooksCalendarButtons();
	initSelect2();

	if (document.querySelector('#dynamic-rating')) {
		dynamicRating();
	}
	if (document.querySelector('.glide')) {
		glide();
	}

  if (document.querySelector(".cloudinary-fileupload")) {
    cloudinaryFileUpload()
  }

	// Sweet Alert d'inscription à la newsletter :
	if (document.querySelector('#newsletter_signin')) {
		initSweetalert('#newsletter_signin', {
			text: 'Votre inscription à la newsletter de BatchCooking Paris a bien été prise en compte !',
			icon: 'success'
		});
	}

	// Sweet Alert d'inscription à la newsletter :
	if (document.querySelector('#newsletter_signin_2')) {
		initSweetalert('#newsletter_signin_2', {
			text: 'Votre inscription à la newsletter de BatchCooking Paris a bien été prise en compte !',
			icon: 'success'
		});
	}

	// Sweet Alert loading cook availabilities :
	loadingSweetalert();

	// Sweet Alert when a cook cancel a session :
	cancelSweetalert();

	if (document.querySelector('#homepagePopupModal')) {
		closeHomepagePopup();
	}
});

require('trix');
require('@rails/actiontext');

import 'controllers';
